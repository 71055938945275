<template>
  <v-container>
    <p>{{ questionLabel }}</p>
    <div v-for="option in options" :key="option.value">
      <v-checkbox
        v-model="isChecked"
        :value="option.value"
        :label="option.text"
        @change="onChange()"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NeutronProtonElectronCheckboxes',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: ['questionLabel', 'checked'],
  data() {
    return {
      isChecked: [],
      options: [
        {text: 'Neutron', value: 'neutron'},
        {text: 'Proton', value: 'proton'},
        {text: 'Electron', value: 'electron'},
      ],
    };
  },
  methods: {
    onChange() {
      this.$emit('change', this.isChecked);
    },
  },
};
</script>

<style scoped></style>
